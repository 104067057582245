import React, { useState, useEffect } from 'react';
import axios from 'axios';
import AssignmentIcon from '@mui/icons-material/Assignment';
import Diversity1Icon from '@mui/icons-material/Diversity1';
import PersonIcon from '@mui/icons-material/Person';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';

export default function CouterHomePage() {
  const [count, setCount] = useState({})
  useEffect(() => {
    const count = async () => {
      try {
        const url = `${process.env.REACT_APP_API_URL}/thongke`;
        const { data } = await axios.get(url, { withCredentials: true });
        setCount(data);
      } catch (err) {
        console.log(err);
      }
    };
    count()
  }, []);
  return (
    <>
      <div className="counter">
        <div className="counter__container">
          <div className="counter__content">
            <AssignmentIcon className="icon" />
            <CountUp end={count.hocBong}>
              {({ countUpRef, start = 0 }) => (
                <VisibilitySensor onChange={start} delayedCall>
                  <span ref={countUpRef} />
                </VisibilitySensor>
              )}
            </CountUp>
          </div>
          <h4 className="title__count">Học bổng</h4>
        </div>
        <div className="counter__container">
          <div className="counter__content">
            <PersonIcon className="icon" />
            {/* <CountUp end={totalchidren}> */}
            <CountUp end={count.treEm} >
              {({ countUpRef, start = 1 }) => (
                <VisibilitySensor onChange={start} delayedCall>
                  <span ref={countUpRef} />
                </VisibilitySensor>
              )}
            </CountUp>
          </div>
          <h4 className="title__count">Trẻ em</h4>
        </div>
        <div className="counter__container">
          <div className="counter__content">
            <Diversity1Icon className="icon" />

            <CountUp end={count.donViTaiTro}>
              {({ countUpRef, start = 1 }) => (
                <VisibilitySensor onChange={start} delayedCall>
                  <span ref={countUpRef} />
                </VisibilitySensor>
              )}
            </CountUp>
          </div>
          <h4 className="title__count">Đơn vị tài trợ</h4>
        </div>
      </div>
    </>
  );
}
