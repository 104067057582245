import React, { useState } from 'react';
import { BsFacebook, BsMessenger, BsLinkedin, BsTwitter, BsGlobe } from 'react-icons/bs';
import PlaceIcon from '@mui/icons-material/Place';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import CallIcon from '@mui/icons-material/Call';
import * as logoThanhDoan from '../../../assets/images/home/logo-thanh-doan.png';
import * as logo from '../../../assets/images/home/logo.png';

export default function Contact() {

  const imgThanhDoan = logoThanhDoan.default;
  const imgLogo = logo.default;

  return (
    <div className='bg-sky-300 pt-5'>
      <div className="bg-white grid grid-rows-1 grid-flow-col gap-24 font-sans p-20">
        <div className="row-span-3 ...">
          <div className="flex flex-col gap-7">
            <img src={imgLogo} alt="logo" />
            <img src={imgThanhDoan} alt="Logo Thành Đoàn" />
          </div>
        </div>
        <div className="col-span-2 ">
          <h1 className='uppercase text-3xl font-black text-sky-600'>Thông tin liên hệ</h1>
          <div className='bg-sky-700 w-full h-1 my-5'>{''}</div>
        </div>
        <div className="row-span-2 col-span-2 ...">
          <div className="grid grid-cols-6 gap-4 -mt-24">
            <div className="col-start-1 col-span-6 ...">
              <div className="flex flex-col gap-2 items-center rounded-lg md:flex-row md:max-w-full">
                <svg xmlns="http://www.w3.org/2000/svg" height="40" width="30" viewBox="0 0 384 512"><path fill="#306ed9" d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z" /></svg>
                <div>
                  <h1 className='uppercase text-sky-800 text-xl font-black'>Thành đoàn - hội đồng đội thành phố hồ chí minh</h1>
                  <p className='text-sky-800'>01 Phạm Ngọc Thạch, Phường Bến Nghé, Quận 1</p>
                </div>
              </div>
            </div>
            <div className="col-start-1 col-end-3 ...">
              <div className="flex flex-col gap-2 items-center rounded-lg md:flex-row md:max-w-full">
                <svg xmlns="http://www.w3.org/2000/svg" height="40" width="30" viewBox="0 0 512 512"><path fill="#306ed9" d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48H48zM0 176V384c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V176L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z" /></svg>
                <p className='text-sky-800'><span className='font-black'>Email:</span> thanhdoan@tphcm.gov.vn</p>
              </div>
            </div>
            <div className="col-end-7 col-span-3">
              <div className="flex flex-col gap-2 items-start rounded-lg md:flex-row md:max-w-full">
                <svg xmlns="http://www.w3.org/2000/svg" height="32" width="24" viewBox="0 0 320 512"><path fill="#306ed9" d="M80 299.3V512H196V299.3h86.5l18-97.8H196V166.9c0-51.7 20.3-71.5 72.7-71.5c16.3 0 29.4 .4 37 1.2V7.9C291.4 4 256.4 0 236.2 0C129.3 0 80 50.5 80 159.4v42.1H14v97.8H80z" /></svg>                
                <div className='flex flex-col gap-2 items-start rounded-lg md:flex-row md:max-w-full'>
                  <p className='text-sky-800 font-black'>Fanpage:</p>
                  <div>
                    <p className='text-sky-800'>Thành đoàn TP. Hồ Chí Minh</p>
                    <p className='text-sky-800'>Thiếu nhi TP. Hồ Chí Minh</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-start-1 col-end-4 ...">
              <div className="flex flex-col gap-2 items-center rounded-lg md:flex-row md:max-w-full px-px">
              <svg xmlns="http://www.w3.org/2000/svg" height="32" width="27" viewBox="0 0 384 512"><path fill="#306ed9" d="M80 0C44.7 0 16 28.7 16 64V448c0 35.3 28.7 64 64 64H304c35.3 0 64-28.7 64-64V64c0-35.3-28.7-64-64-64H80zm80 432h64c8.8 0 16 7.2 16 16s-7.2 16-16 16H160c-8.8 0-16-7.2-16-16s7.2-16 16-16z"/></svg>
                <p className='text-sky-800'><span className='font-black'>Phone:</span> (84.8) 38225124 - 38225146</p>
              </div>
            </div>
            <div className="col-end-7 col-span-3 ...">
              <div className="flex flex-col gap-2 items-center rounded-lg md:flex-row md:max-w-full px-px">
              <svg xmlns="http://www.w3.org/2000/svg" height="36" width="27" viewBox="0 0 448 512"><path fill="#000000" d="M448 209.9a210.1 210.1 0 0 1 -122.8-39.3V349.4A162.6 162.6 0 1 1 185 188.3V278.2a74.6 74.6 0 1 0 52.2 71.2V0l88 0a121.2 121.2 0 0 0 1.9 22.2h0A122.2 122.2 0 0 0 381 102.4a121.4 121.4 0 0 0 67 20.1z"/></svg>
                <p className='text-sky-800'><span className='font-black'>Tiktok:</span> Thiếu nhi TP. Hồ Chí Minh</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
