import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { Box, Grid, Card, Tooltip } from '@mui/material';
import Slider from 'react-slick';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import moment from 'moment';
import { CommentList } from './CommentList';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="left" ref={ref} {...props} />
));

export function TreEmDialog(props) {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const [treEm, setTreEm] = useState({ _id: '', hoTen: '', ngaySinh: '', diaChi: '', SDT: '', truong: '', doiTuong: [] });
  const [images, setImages] = useState([]);
  const [hocTaps, setHocTaps] = useState([]);
  const [hocBongs, setHocBongs] = useState([]);
  const [comments, setComments] = useState([]);

  useEffect(() => {
    const getTreEm = async () => {
      const url = `${process.env.REACT_APP_API_URL}/treem/byId?id=${props._id}`;
      const { data } = await axios.get(url, { withCredentials: true });
      setTreEm(data.data);
      setImages(data.data.hinhAnh);
      getHocTap(props._id);
      getHocBong(props._id);
      getBinhLuan(props._id);
    };
    const getHocTap = async (id) => {
      const url = `${process.env.REACT_APP_API_URL}/hoctap/bytreem?treem=${id}`;
      const { data } = await axios.get(url, { withCredentials: true });
      setHocTaps(data.data);
    };
    const getHocBong = async (id) => {
      const url = `${process.env.REACT_APP_API_URL}/hocbongtreem/bytreem?treem=${id}`;
      const { data } = await axios.get(url, { withCredentials: true });
      setHocBongs(data.data);
    };
    const getBinhLuan = async (id) => {
      const url = `${process.env.REACT_APP_API_URL}/binhluan/getAllByTreEm?treEm=${id}`;
      const { data } = await axios.get(url, { withCredentials: true });
      setComments(data.data);
    };
    getTreEm();
  }, [props._id]);

  // const handleClickOpen = () => {
  //   setOpen(true);
  // };

  // const handleClose = () => {
  //   setOpen(false);
  // };

  return (
    <>
      {/* <Button variant="outlined" onClick={handleClickOpen}>
        Open full-screen dialog
      </Button> */}
      <Dialog
        fullScreen
        open={props.open}
        onClose={props.handleClose}
        TransitionComponent={Transition}
        style={{ paddingLeft: '30%' }}
      >
        <AppBar sx={{ position: 'relative', marginBottom: '10px' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={props.handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Thông tin chung
            </Typography>
          </Toolbar>
        </AppBar>
        <div className="dialog-container px-5">
          <div className="content-container">
            <h5 className="mb-7 font-bold text-center tracking-tight text-gray-900 dark:text-white font-serif text-4xl">{treEm.hoTen}</h5>
            <div className="grid grid-cols-1 gap-1 lg:grid-cols-2">
              <div className="w-4/5 my-auto mx-auto text-center">
                <Slider {...settings}>
                  {images.map((image) => (
                    <img key={image._id} className="h-96 w-auto text-center" src={image.url} alt="" />
                  ))}
                </Slider>
              </div>

              <div className="flex flex-col p-4 leading-normal">
                <p className="mb-3 font-normal text-gray-700 dark:text-gray-400"><b>Ngày sinh:</b> {moment(treEm.ngaySinh).format('DD/MM/YYYY')}</p>
                <p className="mb-3 font-normal text-gray-700 dark:text-gray-400"><b>Số điện thoại:</b> {treEm.SDT}</p>
                <b className="mb-3 text-gray-700 dark:text-gray-400">Hoàn cảnh:</b>
                <p className="mb-3 font-normal text-gray-700 dark:text-gray-400 text-justify"> {treEm.hoanCanh}</p>
              </div>
            </div>
            <button type="button" data-drawer-hide="drawer-right-example" aria-controls="drawer-right-example" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 absolute top-2.5 end-2.5 inline-flex items-center justify-center dark:hover:bg-gray-600 dark:hover:text-white" >
              <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
              </svg>
              <span className="sr-only">Close menu</span>
            </button>
            <p className="mb-4 mt-7 font-bold tracking-tight text-gray-900 dark:text-white font-serif text-xl">Thành tích:</p>
            {hocTaps.map((hoctap) => (
              <>
                {hoctap.thanhTich && (
                  <div className='mb-2'>
                    <p className='ml-3 font-black text-gray-600'>
                      {hoctap.hocKy} - Năm học {hoctap.namHoc}
                    </p>
                    {hoctap.thanhTich.split('\n').map((item, index) => (
                      <React.Fragment key={index}>
                        <p className='ml-8' >{item}</p>
                      </React.Fragment>
                    ))}
                  </div>
                )}
              </>
            ))}

            <p className="mb-4 mt-6 font-bold tracking-tight text-gray-900 dark:text-white font-serif text-xl">Học Bổng:</p>
            <Grid container spacing={3}>
              {hocBongs.map((hocbong, index) => (
                <Grid item xs={6}>
                  <Card className='flex p-2 border-2 border-slate-400 h-28 items-center'>
                    <img src={hocbong.donViBaoTro.logo} alt="" className='w-16 h-16 inline-block my-auto ml-4' />
                    <Box sx={{ flexGrow: 1, minWidth: 0, pl: 5, pr: 1 }}>
                      <Tooltip title={hocbong.donViBaoTro.tenDonVi} placement="top">
                        <Typography variant="subtitle2" noWrap>
                          {hocbong.donViBaoTro.tenDonVi}
                        </Typography>
                      </Tooltip>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Tooltip title={hocbong.hocBong.tenHocBong} placement="right">
                          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
                            {hocbong.hocBong.tenHocBong}
                          </Typography>
                        </Tooltip>
                      </Box>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
                          Giá trị: {hocbong.hocBong.soTien} VND ({hocbong.hocBong.hinhThuc})
                        </Typography>
                      </Box>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
                          {hocbong.namHoanThanh > moment().format('YYYY')
                            ? `Còn lại: ${hocbong.namHoanThanh - moment().format('YYYY')} năm`
                            : ''}
                        </Typography>
                      </Box>
                    </Box>
                  </Card>
                </Grid>
              ))}
            </Grid>
            <div className="bg-sky-600 h-px my-20 w-1/2 mx-auto" />
            <p className="mb-4 mt-16 text-center font-bold tracking-tight text-red-500 dark:text-white font-serif text-5xl">Lời chúc</p>
            {
              treEm._id && <CommentList treEm={treEm.id} comments={comments} />
            }
          </div>
        </div>
      </Dialog>
    </>
  );
  // return (
  //   <>
  //     <Dialog className="dialogtreem" open={props.openDialog} onClose={props.handleClose} style={{ zIndex: 20000 }}>
  //       <div className="info-treem">
  //         <Grid container spacing={1}>
  //           <Grid item xs={6}>
  //             <div className="img-slider-treem">
  //               <Slider {...settings}>
  //                 {images.map((image) => (
  //                   <img src={image.url} alt="cover" />
  //                 ))}
  //               </Slider>
  //             </div>
  //           </Grid>

  //           <Grid item xs={6} sx={{ overflowY: 'auto', height: 500 }}>
  //             <div className="spaced">
  //               <p>
  //                 <b>Họ tên:</b> {treEm.hoTen}
  //               </p>
  //               <p>
  //                 <b>Ngày sinh:</b> {moment(treEm.ngaySinh).format('DD/MM/YYYY')}
  //               </p>
  //               <p>
  //                 <b>Số điện thoại:</b> {treEm.SDT}
  //               </p>
  //               <p>
  //                 <b>Trường:</b> {treEm.truong}
  //               </p>
  //               <p>
  //                 <b>Đối tượng:</b>{' '}
  //                 {treEm.doiTuong.map((doituong, index) =>
  //                   index === treEm.doiTuong.length - 1 ? doituong.ten : `${doituong.ten}, `
  //                 )}
  //               </p>
  //             </div>
  //           </Grid>
  //         </Grid>
  //       </div>
  //       <div className="thanhtich">
  //         <Box sx={{ width: '100%', typography: 'body1' }}>
  //           <TabContext value={tab}>
  //             <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
  //               <TabList onChange={handleChangeTab} aria-label="lab API tabs example">
  //                 <Tab label="Hoàn cảnh" value="1" />
  //                 <Tab label="Thành tích" value="2" />
  //                 <Tab label="Học bổng" value="3" />
  //                 <Tab label="Lời chúc" value="4" />
  //               </TabList>
  //             </Box>
  //             <TabPanel value="1" style={{ height: '250px' }}>
  //               <div className="dialog-container">
  //                 <div className="content-container">
  //                   {treEm.hoanCanh?.split('\n').map((item, index) => (
  //                     <React.Fragment key={index}>
  //                       {item}
  //                       <br />
  //                     </React.Fragment>
  //                   ))}
  //                 </div>
  //               </div>
  //             </TabPanel>
  //             <TabPanel value="2" style={{ height: '250px' }}>
  //               <div className="dialog-container">
  //                 <div className="content-container">
  //                   {hocTaps.map((hoctap, index) => (
  //                     <>
  //                       {hoctap.thanhTich && (
  //                         <div>
  //                           <p>
  //                             <b>
  //                               {hoctap.hocKy} - Năm học {hoctap.namHoc}
  //                             </b>
  //                           </p>
  //                           {hoctap.thanhTich.split('\n').map((item, index) => (
  //                             <React.Fragment key={index}>
  //                               <p style={{ marginLeft: '10px' }}>{item}</p>
  //                             </React.Fragment>
  //                           ))}
  //                         </div>
  //                       )}
  //                     </>
  //                   ))}
  //                 </div>
  //               </div>
  //             </TabPanel>
  //             <TabPanel value="3" style={{ height: '250px' }}>
  //               <div className="dialog-container">
  //                 <div className="content-container">
  //                   <Grid container spacing={1}>
  //                     {hocBongs.map((hocbong, index) => (
  //                       <Grid item xs={12}>
  //                         <Card sx={{ display: 'flex', alignItems: 'center', p: 2, border: '1px solid black' }}>
  //                           <img src={hocbong.donViBaoTro.logo} alt="" style={{ width: 60, height: 60 }} />
  //                           <Box sx={{ flexGrow: 1, minWidth: 0, pl: 2, pr: 1 }}>
  //                             <Tooltip title={hocbong.donViBaoTro.tenDonVi} placement="top">
  //                               <Typography variant="subtitle2" noWrap>
  //                                 {hocbong.donViBaoTro.tenDonVi}
  //                               </Typography>
  //                             </Tooltip>
  //                             <Box sx={{ display: 'flex', alignItems: 'center' }}>
  //                               <Tooltip title={hocbong.hocBong.tenHocBong} placement="right">
  //                                 <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
  //                                   {hocbong.hocBong.tenHocBong}
  //                                 </Typography>
  //                               </Tooltip>
  //                             </Box>
  //                             <Box sx={{ display: 'flex', alignItems: 'center' }}>
  //                               <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
  //                                 Giá trị: {hocbong.hocBong.soTien} VND ({hocbong.hocBong.hinhThuc})
  //                               </Typography>
  //                             </Box>
  //                             <Box sx={{ display: 'flex', alignItems: 'center' }}>
  //                               <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
  //                                 {hocbong.namHoanThanh > moment().format('YYYY')
  //                                   ? `Còn lại: ${hocbong.namHoanThanh - moment().format('YYYY')} năm`
  //                                   : ''}
  //                               </Typography>
  //                             </Box>
  //                           </Box>
  //                         </Card>
  //                       </Grid>
  //                     ))}
  //                   </Grid>
  //                 </div>
  //               </div>
  //             </TabPanel>
  //             <TabPanel value="4" style={{ height: '250px' }}>
  //               <div className="dialog-container">
  //                 <div className="content-container">
  //                   <CommentList treEm={treEm._id} comments={comments} />
  //                 </div>
  //               </div>
  //             </TabPanel>
  //           </TabContext>
  //         </Box>
  //       </div>
  //     </Dialog>
  //   </>
  // );
}
