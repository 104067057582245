
import React from "react";

import { div } from "@mui/material";

export default function Footer() {
  return (

      <div className="bg-sky-600">
        <div className="text-center text-white py-5 " >
        <p className="font-black text-xl">
          CỔNG THÔNG TIN "Vì Đàn Em Thành phố Hồ Chí Minh"
          </p>
          <p className="font-thin text-gray-300 text-lg">
            Đơn vị quản lý: Thành Đoàn - Hội đồng Đội Thành phố Hồ Chí Minh
          </p>
        </div>
      </div>

  );
}


