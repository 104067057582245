import React, { useState, useRef, useEffect } from 'react';
import { Alert } from '@mui/material';
import emailjs from '@emailjs/browser';

export default function Contact() {
  const form = useRef();

  const [openSuccessMessage, setOpenSuccessMessage] = useState('');
  const [openErrMessage, setOpenErrMessage] = useState('');

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_0zdsxki', 'template_gbryx3c', form.current, 'Pjanom3gJGvUA5wb1').then(
      (result) => {
        setOpenSuccessMessage('Đã gửi mail thành công');
      },
      (error) => {
        setOpenErrMessage('Gửi không thành công');
      }
    );
  };
  useEffect(() => {
    setTimeout(() => {
      setOpenSuccessMessage('');
      setOpenErrMessage('');
    }, 3000);
  }, [openErrMessage, openSuccessMessage]);
  return (
    <>
      {openSuccessMessage && (
        <Alert style={{ position: 'fixed', zIndex: 10000, right: 50, top: 150 }} severity="success">
          {openSuccessMessage}
        </Alert>
      )}
      {openErrMessage && (
        <Alert style={{ position: 'fixed', zIndex: 10000, right: 50, top: 150 }} severity="error">
          {openErrMessage}
        </Alert>
      )}
      <div className="form__contact py-10 bg-no-repeat">
        <h2 className='font-black text-4xl'>LIÊN HỆ VỚI CHÚNG TÔI</h2>
        <div className="divider__contactform" />
        <p className='uppercase font-black text-white'>Thành đoàn - hội đồng đội thành phố Hồ Chí Minh</p>
        <p className='text-sm text-gray-200'>01 Phạm Ngọc Thạch, Phường Bến Nghé, Quận 1</p>
        <p className='text-sm text-gray-200'>Email: thanhdoan@tphcm.gov.vn</p>
        <p className='text-sm text-gray-200'>Phone: (84.8) 38225124 - 38225146</p>
        <br />

        <form className='border border-white rounded-xl mx-auto w-2/3 py-5 px-10 bg-black bg-opacity-30' ref={form} onSubmit={sendEmail}>
          <div className=" flex gap-14  items-center justify-center mb-5">
            <div className="w-1/3 flex flex-col gap-5">
              <div className="relative">
                <input name="user_name" type="text" id="floating_outlined" className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-white bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-white focus:outline-none focus:ring-0 focus:border-white peer" placeholder=" " />
                <label htmlFor="floating_outlined" className="absolute text-sm text-white dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-white peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">Họ tên</label>
              </div>
              <div className="relative">
                <input type="text" id="floating_outlined" name="user_email" className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-white bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-white focus:outline-none focus:ring-0 focus:border-white peer" placeholder=" " />
                <label htmlFor="floating_outlined" className="absolute text-sm text-white dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-white peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">Email</label>
              </div>
              <div className="relative">
                <input name="mota" type="text" id="floating_outlined" className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-white bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-white focus:outline-none focus:ring-0 focus:border-white peer" placeholder=" " />
                <label htmlFor="floating_outlined" className="absolute text-sm text-white dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-white peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">Tiêu đề</label>
              </div>
            </div>
            <div className="w-2/3">
              <div className="relative">
                <textarea className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-white bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-white focus:outline-none focus:ring-0 focus:border-white peer" rows="8" name="message" placeholder="" />
                <label htmlFor="floating_outlined" className="absolute text-sm text-white dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-white peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">Nội dung</label>
              </div>
            </div>
          </div>
          <button type="submit" value="submit" className="block mr-0 ml-auto text-white bg-gradient-to-r from-cyan-500 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-cyan-300 dark:focus:ring-cyan-800 rounded-lg px-10 py-2.5 text-center me-2 mb-2 font-black text-base">Gửi</button>
        </form>
      </div>
    </>
  );
}
