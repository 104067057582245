import React from 'react';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export default function SliderHomepage() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplaySpeed: 5000,
    autoplay: true,
  };

  return (
    <div>
      <Slider {...settings}>
        <div className="slider_one">
          <></>
        </div>
        <div className="slider_two">
          <></>
        </div>
        <div className="slider_three">
          <></>
        </div>
      </Slider>
    </div>
  );
}
