import axios from 'axios';
import { Box, Dialog, Grid, Card, Tab, Typography, Tooltip, Pagination } from '@mui/material';
// components
import { useEffect, useState } from 'react';
import moment from 'moment';
import TreEmToolbar from './TreEmToolbar';
import { TreEmDialog } from './tre-em-dialog';
// import { TreEmDrawer } from './tre-em-drawer';

export default function TreEm() {
  const [filterName, setFilterName] = useState('');
  const [doiTuong, setDoiTuong] = useState('');
  const [quan, setQuan] = useState('');
  const [phuong, setPhuong] = useState('');
  const [openWards, setOpenWards] = useState([]);
  const [sponsor, setSponsor] = useState('');
  const [page, setPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(12);
  const [childrenList, setChildrenList] = useState([]);
  const [total, setTotal] = useState(0);
  const [filterNamNhan, setFilterNamNhan] = useState('');
  const [selectedTreEm, setSelectedTreEm] = useState();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const getChildren = async () => {
      try {
        const url = `${process.env.REACT_APP_API_URL}/treem/getAll?perPage=${itemsPerPage}`;
        const { data } = await axios.get(url, { withCredentials: true });
        // const  parse=data.data.email;

        setChildrenList(data.data);

        setTotal(data.total);
      } catch (err) {
        console.log(err);
      }
    };

    getChildren();
  }, []);

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const handleChangeDoiTuong = async (event) => {
    setDoiTuong(event.target.value);
    try {
      const url = `${process.env.REACT_APP_API_URL}/treem/getAll?hoten=${filterName}&doituong=${event.target.value}&curPage=${page}&perPage=${itemsPerPage}&ma_quan=${quan}&ma_phuong=${phuong}&don_vi_tai_tro=${sponsor}`;

      const { data } = await axios.get(url, { withCredentials: true });
      // const  parse=data.data.email;
      setChildrenList(data.data);
      setTotal(data.total);
    } catch (err) {
      console.log(err);
    }
  };

  const handleChangeQuan = async (event) => {
    setQuan(event.target.value);
    if (event.target.value) {
      try {
        const url = `https://sandbox.goship.io/api/v2/districts/${event.target.value}/wards`;
        const { data } = await axios.get(url, {
          headers: {
            'Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6IjYzYTA2MWM1MzI5OGIxYWRmYTllNDhkMzYxZDcxOTNlZmRlMmFmOGRjOWQxMzIwODQyYWI1NDMxMzIzMTU1NTNlYWJkMzIwNDA3YmY3YThkIn0.eyJhdWQiOiIxMyIsImp0aSI6IjYzYTA2MWM1MzI5OGIxYWRmYTllNDhkMzYxZDcxOTNlZmRlMmFmOGRjOWQxMzIwODQyYWI1NDMxMzIzMTU1NTNlYWJkMzIwNDA3YmY3YThkIiwiaWF0IjoxNzEwNjI5MjI5LCJuYmYiOjE3MTA2MjkyMjksImV4cCI6NDg2NjMwMjgyOSwic3ViIjoiMzI2MCIsInNjb3BlcyI6W119.KeuMbOl2YGB8U3WUXqYisyH1kXWVB6oK5_hhyX-CTpAy8tgMNzmyL34nmZ0iPLpRzxbXw0wWgIIH9jYMlqg8pAuVrpuGbU9TorHv5plL1Mp4Atb_7BzQMgvw3DmCqPjzyz6IwC2AruCJaktr30PO4gA6sJ_gHVYK-FKlm-75fJK26gAROrD1_QCMZwEimQbgpsXsBcIu6kvhQ0dKIxrGPRmPSUh41vYTZ5H-v8vWR0QkxlvnsJ2UgP4xvk1qkO5UikExnKbyOEvb7PjDzy_5KHLStCBK8lHUuqWuT_WYsULfvEiASJT6XNRpvv7TzambdYcYSVMY1AoSwaNFj82LjImBt9I8OXOoCjR6XpohUJzWw2ETDjZRM1mDobNr_bdj8PE26vS8-ARFZJcWEcsT-5VfBQF9T3JfO2ewHg5hKuBDVa-WJIaYZ9Fnbz09mJbEDUN_46GRlIr80IF14D9wlfP1fPmke1ncOaiVQUcKudVLmCaD8okypZCokGLUOccNFI1xE5fRmhCX4PAENCDfMETXCP4ZgIQt2cuYYYBJg4YOkcdAMIN6NnIwQ7DhDAdfOPaTyt3eEbC8c_Vp2EER1qjUYKBpv8uDe_18kn2C25IPsWTmdBgXVJw-zvbjG3IStx1d1WmP2A2nrNgwWrhMWrSImAhVENvxyeN8Plml8FU'
          }
        });
        setOpenWards(data.data);
      } catch (err) {
        console.log(err);
      }
    } else setPhuong('');
    try {
      const url = `${process.env.REACT_APP_API_URL}/treem/getAll?hoten=${filterName}&doituong=${doiTuong}&curPage=${page}&perPage=${itemsPerPage}&ma_quan=${event.target.value}&don_vi_tai_tro=${sponsor}`;

      const { data } = await axios.get(url, { withCredentials: true });
      // const  parse=data.data.email;
      setChildrenList(data.data);
      setTotal(data.total);
    } catch (err) {
      console.log(err);
    }
  };

  const handleChangePhuong = async (event) => {
    setPhuong(event.target.value);
    try {
      const url = `${process.env.REACT_APP_API_URL}/treem/getAll?hoten=${filterName}&namNhan=${filterNamNhan}&curPage=${page}&perPage=${itemsPerPage}&ma_quan=${quan}&ma_phuong=${event.target.value}&don_vi_tai_tro=${sponsor}`;

      const { data } = await axios.get(url, { withCredentials: true });
      // const  parse=data.data.email;
      setChildrenList(data.data);
      setTotal(data.total);
    } catch (err) {
      console.log(err);
    }
  };

  const handleChangeSponsor = async (event) => {
    setSponsor(event.target.value);
    try {
      const url = `${process.env.REACT_APP_API_URL}/treem/getAll?hoten=${filterName}&namNhan=${filterNamNhan}&curPage=${page}&perPage=${itemsPerPage}&ma_quan=${quan}&ma_phuong=${phuong}&don_vi_tai_tro=${event.target.value}`;
      const { data } = await axios.get(url, { withCredentials: true });
      // const  parse=data.data.email;
      setChildrenList(data.data);
      setTotal(data.total);
    } catch (err) {
      console.log(err);
    }
  };

  const handleSearch = async (event) => {
    if (event.key === 'Enter' || !event.key) {
      try {
        const url = `${process.env.REACT_APP_API_URL}/treem/getAll?hoten=${filterName}&doituong=${doiTuong}&curPage=${page}&perPage=${itemsPerPage}&ma_quan=${quan}&ma_phuong=${phuong}&don_vi_tai_tro=${sponsor}`;

        const { data } = await axios.get(url, { withCredentials: true });

        setChildrenList(data.data);
        setTotal(data.total);
      } catch (err) {
        console.log(err);
      }
    }
  };

  const handleClickDetailChildren = (id) => {
    setSelectedTreEm(id);
    handleClickOpen()
  };

  const handleChangePage = async (event, newPage) => {
    setPage(newPage - 1);
    try {
      const url = `${process.env.REACT_APP_API_URL}/treem/getAll?hoten=${filterName}&namNhan=${filterNamNhan}&curPage=${newPage}&perPage=${itemsPerPage}&ma_quan=${quan}&ma_phuong=${phuong}&don_vi_tai_tro=${sponsor}`;

      const { data } = await axios.get(url, { withCredentials: true });
      setChildrenList(data.data);
      setTotal(data.total);
    } catch (err) {
      console.log(err);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className='mt-4' >
      <div className='flex justify-center items-center'>
        <TreEmToolbar
          filterName={filterName}
          doiTuong={doiTuong}
          onFilterName={handleFilterByName}
          handleChangeDoiTuong={handleChangeDoiTuong}
          quan={quan}
          handleChangeQuan={handleChangeQuan}
          openWards={openWards}
          phuong={phuong}
          handleChangePhuong={handleChangePhuong}
          sponsor={sponsor}
          handleChangeSponsor={handleChangeSponsor}
          onClickSearch={handleSearch}
        />
      </div>
      <div style={{ margin: '20px 50px' }}>
        <Grid container spacing={3}>
          {childrenList?.map((child) => (
            <Grid key={child._id} item xs={4}>
              <Card
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  p: 2,
                  border: '1px solid #99FFFF',
                  '&:hover': {
                    border: '1px solid black',
                  },
                }}

              >
                <Grid item xs={3}>
                  <img src={child.hinhAnh[0].url} alt="" style={{ width: 100, height: 100, margin: 'auto' }} />
                </Grid>
                <Grid item xs={9}>
                  <Box sx={{ flexGrow: 1, minWidth: 0, pl: 2, pr: 1, color: '#1E90FF' }}>
                    <Typography className='hover:cursor-pointer focus:text-sky-200' variant="subtitle2" noWrap
                      onClick={(e) => {
                        handleClickDetailChildren(child._id);
                      }}
                      data-drawer-target="drawer-right-example" data-drawer-show="drawer-right-example" data-drawer-placement="right" aria-controls="drawer-right-example">
                      {child.hoTen} - {moment(child.ngaySinh).format('DD/MM/YYYY')}
                    </Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
                        <b>Đối tượng:</b>{' '}
                        {child.doiTuong.map((doituong, index) =>
                          index === child.doiTuong.length - 1 ? doituong.ten : `${doituong.ten}, `
                        )}
                      </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
                        <b>Hoàn cảnh:</b> {child.hoanCanh}
                      </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
                        <b>Đơn vị bảo trợ:</b>{' '}
                        {child.donViBaoTro.map((donvi, index) =>
                          index === child.donViBaoTro.length - 1 ? donvi.tenDonVi : `${donvi.tenDonVi}, `
                        )}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              </Card>
            </Grid>
          ))}
        </Grid>
      </div>

      {/* <TreEmDrawer _id={selectedTreEm} /> */}

      <TreEmDialog open={open} _id={selectedTreEm} handleClose={handleClose} />
      <Box sx={{ p: 3, display: 'flex', justifyContent: 'center' }}>
        <Pagination count={Math.ceil(total / itemsPerPage)} page={page + 1} onChange={handleChangePage} />
      </Box>
    </div>
  );
}
